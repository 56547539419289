<template>
  <div class="news-item">
    <div class="item-new-golstats">
      <div class="item-new-golstats__container-jersey" :class="{ 'is-player': newItem.isType === 1 }">
        <img
          :src="newItem.isType === 1 ? `${playersImagesUrl}${newItem.playerId}.png` : newItem.logo"
          :style="newItem.isType === 1 ? `border-radius: 5px;` : ``"
        />
        <div
          v-if="newItem.isType == 1"
          class="item-new-golstats__container-jersey__country"
          :style="{ 'background-image': `url(${newItem.logo})` }"
        ></div>
      </div>
      <div class="item-new-golstats__container-new">
        <div class="item-new-golstats__container-new__title">{{ newItem.title }}</div>
        <div
          class="item-new-golstats__container-new__nota"
          v-html="getFormattedBullet(newItem.description)"
          @click="onClickNewItem"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GSDataNewsItem',
  props: {
    newItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      playersImagesUrl: 'https://az755631.vo.msecnd.net/players-profile/',
    };
  },
  methods: {
    getFormattedBullet(bullet) {
      if (bullet) {
        let formattedBullet = bullet;
        const matches = bullet.match(/\*/g);
        if (matches && matches.length) {
          if (matches.length % 2 === 0) {
            for (let i = 1; i <= matches.length; i++) {
              if (i % 2 === 0) {
                formattedBullet = formattedBullet.replace('*', '</span>');
              } else {
                formattedBullet = formattedBullet.replace(
                  '*',
                  // eslint-disable-next-line max-len
                  '<span class="item-click-text"  style="font-family: Avenir-Bold; text-decoration: underline; "><style>.item-click-text</style>',
                );
              }
            }
          } else {
            formattedBullet = bullet.replaceAll('*', '');
          }
        }
        return `${formattedBullet}`;
      }
      return '';
    },
    onClickNewItem() {
      this.$emit('open-media', this.newItem);
    },
  },
};
</script>

<style scoped lang="scss">
.news-item {
  width: 100%;
  border-radius: 7px;
  border: solid 1px #c8c8c8;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.item-new-golstats {
  width: 100%;
  height: 380px;
  &__container-new {
    position: absolute;
    width: 92%;
    height: 114px;
    margin-left: 4%;
    float: left;
    text-align: center;
    top: 60%;
    &__title {
      width: 100%;
      height: 22px;
      font-family: 'Circular-Std-Bold';
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 22px;
      letter-spacing: -0.46px;
      text-align: center;
      color: #f00;

      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (max-width: 1328px) {
        font-size: 26px;
      }
      @media screen and (max-width: 1100px) {
        font-size: 25px;
      }
      @media screen and (max-width: 900px) {
        font-size: 24px;
      }
      @media screen and (max-width: 800px) {
        font-size: 22px;
      }
      @media screen and (max-width: 530px) {
        font-size: 17px;
        height: 17px;
        line-height: 16px;
        margin-bottom: 1px;
      }
    }
    &__nota {
      width: 100%;
      min-height: 50px;
      font-family: 'Avenir-Medium';
      font-size: 17px;
      height: 95px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 23px;
      letter-spacing: -0.4px;
      text-align: center;
      color: #132839;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (max-width: 1328px) {
        font-size: 20px;
      }
      @media screen and (max-width: 1100px) {
        font-size: 19px;
      }
      @media screen and (max-width: 900px) {
        font-size: 18px;
      }
      @media screen and (max-width: 800px) {
        font-size: 17px;
      }
      @media screen and (max-width: 628px) {
        -webkit-line-clamp: 5;
        height: 125px;
      }
      @media screen and (max-width: 530px) {
        -webkit-line-clamp: 3;
        height: 40px;
        font-size: 12px;
        line-height: 16px;
        width: 97%;
      }
      &__decoration {
        font-family: 'Avenir-Bold';
        text-decoration: underline;
      }
    }

    @media screen and (max-width: 530px) {
      top: 60%;
    }
  }
  &__container-jersey {
    width: 108px;
    height: fit-content;
    margin-left: 26px;
    margin-top: 60px;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-74%, -153%);
    @media screen and (max-width: 530px) {
      width: 88px;
      margin-top: 50px;
    }
    &__country {
      width: 42px;
      height: 42px;
      position: absolute;
      bottom: -4px;
      left: -16px;

      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
      @media screen and (max-width: 530px) {
        width: 28px;
        height: 28px;
        left: -10px;
        bottom: -1px;
      }
    }
  }

  @media screen and (max-width: 530px) {
    height: 264px;
  }
}
.golstats-data-new {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 1328px) {
    height: 366px;
  }
  @media screen and (max-width: 530px) {
    height: 106px;
  }
  &__item {
    width: 100%;
    height: 400px;
    float: left;
    @media screen and (max-width: 1328px) {
      height: 366px;
    }
    @media screen and (max-width: 530px) {
      height: 106px;
    }
  }
}
.is-player {
  width: 80px;
  @media screen and (max-width: 530px) {
    width: 62px;
    margin-left: 12px;
  }
}
</style>
